import React from 'react';
import { Breadcrumbs, ButtonGoBack, Layout, SEO } from '@componentsShared';
import { ContainerPortfolio } from 'src/components/organisms/containerPortfolio';
import { WrapperPortfolio } from './styled';
import { ContentPageHeader } from '../pageDynamic/styled';
import { graphql, useStaticQuery } from 'gatsby';
import { colorText } from '@utils/colorText';
import Viewport from 'src/shared/hooks/viewport';
import { getMetadataPage } from '@utils/metadata';
const GamePortfolio = () => {
    const GamesPortfolio = useStaticQuery(graphql`
        query GamesPortfolio {
            allContentfulPaginaInternaFooter(filter: { page: { eq: "PortafolioJuegos" } }) {
                nodes {
                    title
                    slug
                    pageIcon {
                        description
                        file {
                            url
                        }
                    }
                    secondarySection {
                        ... on ContentfulSeccionInternaFooter {
                            sectionName
                            sectionContent {
                                ... on ContentfulCardPortafolio {
                                    title
                                    image {
                                        file {
                                            url
                                        }
                                        description
                                    }
                                    slug
                                }
                            }
                        }
                    }
                    imageText {
                        title
                        mediaFile {
                            file {
                                url
                            }
                            description
                        }
                        text {
                            raw
                        }
                    }

                    HowToClaimYourPrize {
                        image {
                            file {
                                url
                            }
                            description
                        }
                        text {
                            raw
                        }
                    }

                    metaData {
                        title
                        descripcion
                        keyWords
                        image {
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    const viewport = Viewport();

    const { title, slug, pageIcon, secondarySection, metaData, imageText, HowToClaimYourPrize } =
        GamesPortfolio.allContentfulPaginaInternaFooter.nodes[0];

    let crumbs = [
        {
            crumbLabel: 'Inicio',
            pathname: '/'
        },
        {
            crumbLabel: 'Portafolio de juegos',
            pathname: 'portafolio-juegos/'
        }
    ];

    return (
        <Layout>
            <SEO {...getMetadataPage(metaData, slug || '/portafolio-juegos/')} />
            <WrapperPortfolio>
                {viewport.desktop ? <Breadcrumbs crumbs={crumbs} /> : <ButtonGoBack />}

                <ContentPageHeader>
                    <img src={pageIcon.file.url} alt={pageIcon.description} />
                    <h1 dangerouslySetInnerHTML={{ __html: colorText(title) }} />
                </ContentPageHeader>
                <ContainerPortfolio
                    games={secondarySection}
                    retentionInfo={imageText[0]}
                    HowToClaimYourPrize={HowToClaimYourPrize}
                />
            </WrapperPortfolio>
        </Layout>
    );
};

export default GamePortfolio;
