import { ButtonIcon, Carousel } from '@componentsShared';
import React, { useState } from 'react';
import Slider from 'react-slick';
import { IArrow } from 'src/components/molecules/bannerHomePpal/types';
import Viewport from 'src/shared/hooks/viewport';
import { ContentPageHeader } from '../../templates/pageDynamic/styled';
import {
    ArrowCustom,
    BackBox,
    ContentGames,
    BoxGame,
    ContentPortfolio,
    PortfolioBanner,
    TitleBannerPortfolio,
    RetentionBannerWrapper,
    RetentionBannerText,
    RetentionBannerImage,
    ClaimYourPrizeCard,
    ClaimYourPrizeImage,
    ClaimYourPrizeText,
    ClaimYourPrizeStep,
    ClaimYourWrapper
} from './styled';
import siteUrl from '@utils/siteUrl';
import { colorText } from '@utils/colorText';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Arrow } from '../containerAboutUsTabs/styled';

type IPropsSampleArrow = {
    onClick?: () => void;
    arrow?: string;
};

const SampleArrow = (props: IPropsSampleArrow) => {
    const { onClick, arrow } = props;

    return (
        <Arrow onClick={onClick} className={arrow}>
            <ButtonIcon
                icon={`angle-${arrow}`}
                colorDefault={'#0DF96C'}
                title={arrow === 'left' ? 'Anterior' : 'Siguiente'}
                size="small"
                disabled={false}
            />
        </Arrow>
    );
};
export const ContainerPortfolio = (props: any) => {
    const { games, retentionInfo, HowToClaimYourPrize } = props;
    const viewport = Viewport();

    const settingsSlider = {
        infinite: viewport.mobile ? true: false,
        slidesToShow: viewport.mobile ? 1 : viewport.tablet ? 2 : 6,
        className: 'center',
        swipeToSlide: true,
        arrows: true,
        nextArrow: <SampleArrow arrow="right" />,
        prevArrow: <SampleArrow arrow="left" />,
        responsive: [
            {
                breakpoint: 1230,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const settingsAward = {
        dots: true,
        infinite: false,
        autoplay: false,
        // centerMode: !!viewport.mobile,
        centerMode: false,
        // centerPadding: '30px',
        speed: 500,
        slidesToShow: viewport.mobile ? 1 : viewport.tablet ? 2 : 3,
        // slidesToShow: viewport.mobile ? 1 : 3,
        slidesToScroll: 1,
        arrows: false,
        initialSlide: 0,
    };

       return (
        <ContentPortfolio>
            <ContentGames>
                <Slider {...settingsSlider} infinite={viewport.mobile ? true: games[0].sectionContent.length > 5}> 
                {games[0].sectionContent.map((game: any, index: any) => {
                    // const [show, setShow] = useState(false);
                    return (
                        <BoxGame
                            href={siteUrl + '/juegos' + game.slug}
                            show={false}
                            // onClick={() => {
                            //     setShow(!show);
                            // }}
                        >
                            <img src={game.image.file.url} alt={game.image.description} />
                        </BoxGame>
                    );
                })}
                </Slider>
            </ContentGames>



            <PortfolioBanner>
                <TitleBannerPortfolio>
                    <h2>
                        <span>¿Cómo</span> reclamar tu premio?{' '}
                    </h2>
                </TitleBannerPortfolio>

                <ClaimYourWrapper>
                    <Carousel customSettings={settingsAward}>
                        {HowToClaimYourPrize.map((item: any, index: number) => {
                            return (
                                <ClaimYourPrizeCard>
                                    <ClaimYourPrizeStep>{index + 1}</ClaimYourPrizeStep>
                                    <ClaimYourPrizeImage>
                                        <img
                                            src={item.image.file.url}
                                            alt={item.image.description}
                                        />
                                    </ClaimYourPrizeImage>

                                    <ClaimYourPrizeText>
                                        {documentToReactComponents(JSON.parse(item.text.raw))}
                                    </ClaimYourPrizeText>
                                </ClaimYourPrizeCard>
                            );
                        })}
                    </Carousel>
                </ClaimYourWrapper>
            </PortfolioBanner>



            <PortfolioBanner>
                <TitleBannerPortfolio>
                    <h2 dangerouslySetInnerHTML={{ __html: colorText(retentionInfo.title) }} />
                </TitleBannerPortfolio>

                <RetentionBannerWrapper>
                    <RetentionBannerText>
                        {documentToReactComponents(JSON.parse(retentionInfo.text.raw))}
                    </RetentionBannerText>
                    <RetentionBannerImage>
                        <img
                            src={retentionInfo.mediaFile.file.url}
                            alt={retentionInfo.mediaFile.description}
                        />
                    </RetentionBannerImage>
                </RetentionBannerWrapper>
            </PortfolioBanner>

        </ContentPortfolio>
    );
};
