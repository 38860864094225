import { breakpoint } from 'src/shared/styles/variables';
import styled, { keyframes } from 'styled-components';
export type TGame = {
    show?: boolean;
};
export const ContentPortfolio = styled.div`
    margin-top: 25px;
    width: 100%;
`;
const down = keyframes`
    from  {top:0px}
    to {top:30px}

`;

export const ContentGames = styled.div`
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    .slick-slide {
        display: flex;
        justify-content: center;
    }
    .slick-slider .slick-list {
        padding: 40px 0px 10px;
        width: 90%;
        margin: 0 auto;
    }
    .slick-initialized .slick-slide {
        padding-right: 5px;
        padding-left: 5px;
    }
    .slick-dots {
        text-align: center;
        width: auto;
        bottom: 25px;
        left: 0;
        right: 0;
        margin: 0 auto;
        li {
            width: 15px;
            height: 15px;
            &.slick-active {
                button {
                    &:before {
                        font-size: 12px;
                        line-height: 14px;
                        width: 11px;
                        height: 11px;
                        color: transparent;
                        box-shadow: 0px 0px 6px 1.5px ${(props) => props.theme.colors.alerts.yellow};
                        border: 2px solid ${(props) => props.theme.colors.alerts.yellow};
                        top: -2px;
                    }
                }
            }
            button {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                &:before {
                    font-size: 10px;
                    line-height: 12px;
                    color: transparent;
                    border: 2px solid ${(props) => props.theme.colors.disabled.gray};
                    border-radius: 50%;
                    width: 7px;
                    height: 7px;
                    opacity: 1;
                }
            }
        }
    }
`;

export const BoxGame = styled.a<TGame>`
    cursor: pointer;
    margin: 0 14px;
    max-width: 96px;
    height: auto;
    position: relative;
    background: #1d1828;
    border: 1px solid #c5c3c9;
    box-sizing: border-box;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    /* animation: ${down};
    animation-duration: 2s;
    animation-play-state: ${(props) => (props.show ? 'running' : 'paused')};
    animation-fill-mode: ${(props) => (props.show ? 'forwards' : 'backwards')}; */
    &:hover {
        margin-top: ${(props) => !props.show && '0px'};
        border: 2px solid #00d455;
        transition: all 0.4s 0s ease;
        ${breakpoint('md')`
        margin-top: -30px;
        `};
    }

    &:hover::before {
        ${breakpoint('md')`
            background: transparent;
            content: " ";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 2rem;
        `};
    }
    img {
        width: 100%;
        border-radius: 10px;
    }

    ${breakpoint('md')`
        margin-right: 10px;
    `}
`;

export const BackBox = styled.div`
    width: 100%;
    height: 47px;
    top: 42%;
    position: absolute;
    background: #3e3a47;
    border-radius: 12px;
    ${breakpoint('lg')({
        maxWidth: '972px'
    })}
`;
export const ArrowCustom = styled.div`
    display: flex;
    cursor: pointer;
    top: 47%;
    position: absolute;
    margin: auto 0;
    z-index: 4;
    svg {
        path,
        circle {
            transition: all 0.3s ease-in-out;
        }
    }
    /* &:hover {
        svg {
            circle {
                fill: ${(props) => props.theme.colors.principal.greenBase};
            }
            path {
                fill: ${(props) => props.theme.colors.text.white};
            }
        }
    } */
    &.prev {
        left: 2%;
    }
    &.next {
        right: 2%;
        transform: rotate(180deg);
    }
`;

export const PortfolioBanner = styled.div`
    width: 90%;
    max-width: 350px;
    margin: 50px auto;
    background: #004e2d;
    border: 1px solid #c5c3c9;
    border-radius: 16px;
    justify-content: space-between;

    ${breakpoint('md')`
        margin-top: 40px;
        max-width: 700px;
    `}

    ${breakpoint('lg')`
        width: 100%;
        margin-top: 40px;
        background: none;
        border: none;
        max-width: 1200px;

    `}
`;

export const TitleBannerPortfolio = styled.div`
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 0.5px solid ${(props) => props.theme.colors.disabled.gray};
    text-align: center;
    padding: 15px 10px;

    ${breakpoint('lg')`
      width: 100%;
      padding-top: 25px;
      padding-bottom: 24px;
      border-bottom: none;
      border-top: 1px solid ${(props) => props.theme.colors.disabled.gray}
    `}

    & > h2 {
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 120%;

        ${breakpoint('lg')({
            fontSize: '28px'
        })}

        span {
            color: ${(props) => props.theme.colors.accents.base.yellow};
        }
    }
`;

export const RetentionBannerWrapper = styled.div`
    padding: 0 25px 20px 25px;
    flex-direction: row-reverse;
    position: relative;
    z-index: 1;
    ${breakpoint('md')`
        margin-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row-reverse;
    `}

    ${breakpoint('lg')`
        margin-top: 20px;
    `}

    &:before {
        z-index: -1;
        content: '';
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 120px;
        background-color: #004e2d;
        border-radius: 16px;
    }
`;

export const RetentionBannerText = styled.div`
    margin: 30px auto;
    width: 90%;
    max-width: 235px;
    text-align: center;

    ${breakpoint('md')`
        max-width: 300px;
        margin: 0 40px ;
        text-align: left;

    `}

    ${breakpoint('lg')`
        max-width: 650px;
    `}
    p {
        font-size: 16px;
        line-height: 19px;
        font-family: ${(props) => props.theme.fonts.lf_regular};

        b {
            font-family: ${(props) => props.theme.fonts.lf_bold};
            color: ${(props) => props.theme.colors.accents.base.yellow};
        }
    }
`;

export const RetentionBannerImage = styled.div`
    width: 90%;
    max-width: 275px;
    margin: 0 auto;

    img {
        width: 100%;
        object-fit: cover;
    }
    ${breakpoint('md')`
        margin: 0 40px ;
    `}
    ${breakpoint('lg')`
        max-width: 400px;


    `}
`;

export const ClaimYourPrizeCard = styled.div`
    position: relative;
    border: 1px solid #ffe000;
    border-radius: 16px;
    width: 260px !important;
    height: 260px;
    margin: 0 auto;
    background-color: #1d182880;
    padding: 15px;
    text-align: center;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${breakpoint('lg')`
        width: 285px !important;
        height: 225px;
        background-color: #1d1828;

    `}
`;

export const ClaimYourWrapper = styled.div`
    margin-top: 20px;
    position: relative;
    z-index: 1;
    .slick-dots {
        text-align: center;
        width: auto;
        bottom: 10px !important;
        left: 0;
        right: 0;
        margin: 0 auto;
        li {
            width: 15px;
            height: 15px;
            &.slick-active {
                button {
                    &:before {
                        font-size: 12px;
                        line-height: 14px;
                        width: 11px;
                        height: 11px;
                        color: transparent;
                        box-shadow: 0px 0px 6px 1.5px ${(props) => props.theme.colors.alerts.yellow};
                        border: 2px solid ${(props) => props.theme.colors.alerts.yellow};
                        top: -2px;
                    }
                }
            }
            button {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                &:before {
                    font-size: 10px;
                    line-height: 12px;
                    color: transparent;
                    border: 2px solid ${(props) => props.theme.colors.disabled.gray};
                    border-radius: 50%;
                    width: 7px;
                    height: 7px;
                    opacity: 1;
                }
            }
        }
    }

    &:before {
        z-index: -1;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 120px;
        background-color: #004e2d;
        border-radius: 16px;
    }
`;
export const ClaimYourPrizeStep = styled.span`
    font-size: 40px;
    width: 66px;
    height: 66px;
    border-radius: 100%;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    color: ${(props) => props.theme.colors.accents.base.yellow};
    background: #1d1828;
    border: 1px solid ${(props) => props.theme.colors.accents.base.yellow};
    display: grid;
    place-items: center;

    position: absolute;
    top: -33px;
    z-index: 1;

    ${breakpoint('lg')`
       left: -33px

    `}
`;

export const ClaimYourPrizeImage = styled.div`
    height: 65px;
    margin-bottom: 15px;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &::after {
        position: absolute;
        content: '';
        width: 80px;
        height: 80px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) matrix(1, 0, 0, -1, 0, 0);

        background: #0df96c;
        opacity: 0.15;
        filter: blur(10px);
    }
`;
export const ClaimYourPrizeText = styled.div`
    p {
        font-size: 14px;
        line-height: 18px;
        font-family: ${(props) => props.theme.fonts.lf_regular};

        b {
            font-family: ${(props) => props.theme.fonts.lf_bold};
        }
    }
`;
